import type { AppProps } from 'next/app';
import { ReactElement, ReactNode } from 'react';
import { NextPage } from 'next';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'antd/dist/reset.css';
import '@/styles/globals.scss';
import ErrorBoundary from '@/components/ErrorBoundary';
import Head from 'next/head';
import AuthProvider from '@/configs/Auth/client';
import { ThemeProvider } from '@/provider/themeProvider';
import { OtpProvider } from '@/provider/otpProvider';
import { ChatClient, ChatClientProvider } from '@/configs/Chat';
import { NextFontWithVariable } from 'next/dist/compiled/@next/font';
import localFont from 'next/font/local';
import { DeviceProvider } from '@/provider/deviceProvider';

const poppinsLocal = localFont({
  src: [
    {
      path: '../../public/fonts/FZ_Poppins-Regular.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../public/fonts/FZ_Poppins-Italic.ttf',
      weight: '400',
      style: 'italic',
    },
    {
      path: '../../public/fonts/FZ_Poppins-SemiBold.ttf',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../../public/fonts/FZ_Poppins-SemiBoldItalic.ttf',
      weight: '600',
      style: 'italic',
    },
    {
      path: '../../public/fonts/FZ_Poppins-Bold.ttf',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../../public/fonts/FZ_Poppins-BoldItalic.ttf',
      weight: '700',
      style: 'italic',
    },
  ],
  variable: '--font-poppins',
});

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  // eslint-disable-next-line no-unused-vars
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });
let chatClient: ChatClient;

// Prevent creating multiple instances of ChatClient in development mode
if (process.env.NODE_ENV === 'production') {
  chatClient = new ChatClient();
} else {
  if (!global.chatClient) {
    global.chatClient = new ChatClient();
  }
  chatClient = global.chatClient;
}

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const deviceType = pageProps?.deviceType;

  return (
    <>
      <Head>
        <title>Apollo: Giải pháp ứng dụng AI</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <main className={`${(poppinsLocal as NextFontWithVariable)?.variable}`}>
        <ErrorBoundary>
          <ThemeProvider>
            <AuthProvider>
              <ChatClientProvider client={chatClient}>
                <QueryClientProvider client={queryClient}>
                  <DeviceProvider defaultDeviceType={deviceType}>
                    <OtpProvider>{getLayout(<Component {...pageProps} />)}</OtpProvider>
                  </DeviceProvider>
                </QueryClientProvider>
              </ChatClientProvider>
            </AuthProvider>
          </ThemeProvider>
        </ErrorBoundary>
      </main>
    </>
  );
}
