import { showMessage } from '@/components/messages/GMessage';
import { toQueryParams } from '@/configs/Auth/utils/functions';
import { APIResponse } from '@/interfaces';
import lodash from '@/utils/lodash';
import dayts from './time';
import { twMerge } from 'tailwind-merge';
import { clsx, type ClassValue } from 'clsx';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getOptionFromObj = (obj) => {
  return lodash.map(obj, (val: string, key: string) => ({
    label: val,
    value: key,
  }));
};

export function convertToOptions(
  collection: any[],
  value: string,
  label: string
): { value: number; label: string }[] {
  return lodash.reduce(
    collection,
    (total, item) => {
      if (!lodash.isNil(item)) {
        const option = {
          value: item?.[value],
          label: item?.[label],
        };
        return [...total, option];
      }
      return total;
    },
    []
  );
}

export function normalizeText(str: string) {
  if (typeof str !== 'string') return '';
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D');
}

export function searchValueOptions(input, option) {
  const label = normalizeText(option?.label?.toString());
  const value = option?.value?.toString();
  const searchString = normalizeText(input);

  return label?.indexOf(searchString) >= 0 || value === searchString;
}

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const uid = (len) => {
  const buf = [],
    chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
    charlen = chars.length;
  for (let i = 0; i < len; ++i) buf.push(chars[getRandomInt(0, charlen - 1)]);
  return buf.join('');
};

export const getCurrentPath = () => {
  if (!window) return '/';
  const currentPath = window.location.href.replace(window.location.origin, '');
  return toQueryParams({ current: currentPath });
};

export const select = ({ data, error }: APIResponse) => {
  if (error) {
    showMessage.error(error);
    return { total: 0, records: [] };
  }
  return data;
};

export const filterInvalidField = (params): any => {
  return Object.keys(params).reduce(function (previous, key) {
    if (
      (Array.isArray(params[key]) && !params[key].length) ||
      params[key] === null ||
      params[key] === undefined ||
      params[key] === ''
    )
      return previous;
    return { [key]: params[key], ...previous };
  }, {});
};

export const sortNumber = (x: number, y: number) => x - y;

export const sortDate = (x: string, y: string, format = 'HH:mm:ss DD/MM/YYYY') => {
  if (!x || !y) return;
  return dayts(x, format).unix() - dayts(y, format).unix();
};

export const sortString = (x: string, y: string) => x?.toString()?.localeCompare(y?.toString());

const defaultSort = (a, b) => (a < b ? -1 : 1);

export const sorter = (obj1: object, obj2: object, keyCompare: string, sortFun: any = defaultSort) =>
  sortFun(obj1[keyCompare], obj2[keyCompare]);

export const capitalizeFirstLetter = (string) => string?.charAt?.(0)?.toUpperCase() + string?.slice(1);

export const createQueryString = (obj: Record<string, any>): string => {
  return Object.keys(obj)
    .map((key) => {
      const value = obj[key];
      if (!value) return null;
      const values = Array.isArray(value) ? value.join(',') : value;
      return `${key}=${encodeURIComponent(values)}`;
    })
    .filter((query) => query)
    .join('&');
};

export const formatNumber = (value: number, precision: number = 0, suffix: string = '') => {
  let result = '0';

  if (value) {
    result = value.toLocaleString(undefined, {
      maximumFractionDigits: precision,
    });
  }

  return `${result}${suffix}`;
};

export const convertToBillions = (value: number, suffix: number = 2): string => {
  return `${formatNumber(value / 1.0e9, suffix)} tỷ đồng`;
};

export const getIndexOfKey = (key, obj) => Object.keys(obj).indexOf(key);

export const findNearestMaxLength = (num: number) => {
  const devider = Math.pow(10, String(num?.toFixed(0)).length - 1);
  const nearest = Math.ceil(num / devider) * devider;

  if (nearest < num) {
    return nearest + devider;
  }

  if (nearest - num <= devider / 2) {
    return Math.ceil((num + devider) / devider) * devider;
  }

  return nearest;
};

export const convertToMb = (size: number) => {
  if (!size) return '0KB';

  const MB = 1024 * 1024;

  if (size < MB) {
    return `${(size / 1024).toFixed(2)}KB`;
  }

  return `${(size / (1024 * 1024)).toFixed(2)}MB`;
};
