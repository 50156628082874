/* eslint-disable max-lines */
import { convertMessages, uid } from './utils';
import { MESSAGES_LIMIT, WsListenerType } from './constants';
import {
  IChannelID,
  IChannelMessage,
  IChannels,
  IGetChannelsInfoParams,
  IGroupPath,
  IHandleGetListMessageParams,
  IHandleSend,
  IHandleSendMessages,
  IMsgType,
} from './interfaces';
import {
  getChannels,
  getChannelsInfo,
  getListMessages,
  IGetChannelsParams,
  sendMessage,
  uploadFile,
} from './services/gamp-chat.service';
import { showMessage } from '@/components/messages/GMessage';
import { Logger } from './modules/Logger';
import { Emitter, ISubcribeParams } from './modules/Emitter';
import { IChannelMessageMode, InternalStorage } from './modules/InternalStorage';
import { WSClient } from './modules/WSClient';

enum IStatus {
  LOADING = 'loading',
  IDLE = 'idle',
}

export class ChatClient {
  private client: WSClient;
  private logger: Logger;
  private emitter: Emitter;
  private internalStorage: InternalStorage;
  private messageStatus: Map<IChannelID, IStatus>;

  constructor() {
    // Does not run in server side
    if (typeof window === 'undefined') return;

    this.logger = new Logger();
    this.internalStorage = new InternalStorage(this.logger);
    this.emitter = new Emitter(this.logger);
    this.client = new WSClient(this.logger, this.emitter, this.internalStorage, this.onReconnect);

    this.messageStatus = new Map();
  }

  private onReconnect = () => {
    const { currentSubscribedChannelID, misc } = this.internalStorage.data;
    this.logger.info('On reconnect ws - getting channels and messages');

    for (const channel_id of currentSubscribedChannelID) {
      this.onGetMessages({ channel_id }, [IChannelMessageMode.BEFORE, IChannelMessageMode.MAX_AFTER]);

      this.client.wsClient?.send(`${misc.token}|unsub|chats_channel_${channel_id}`);
      this.client.wsClient.send(`${misc.token}|sub|chats_channel_${channel_id}`);
    }
  };

  private _subcribeConnectWS = () => {
    if (this.client.isWsConnected) return;
    return this.emitter.internalSubscribe(WsListenerType.INTERNAL_CONNECT_WS);
  };

  // Must get group path before getting others
  private _subcribeGetGroupPath = () => {
    if (this.internalStorage.data.misc.groupPath) return;
    return this.emitter.internalSubscribe(WsListenerType.INTERNAL_GETTING_GROUP_PATH);
  };

  // Prevent multiple request to get messages in the same channel at the same time
  private _subcribeGetMessages = (channelID: IChannelID) => {
    return this.emitter.internalSubscribe(WsListenerType.INTERNAL_GETTING_MESSAGE, channelID);
  };

  // Remove messages cache after CACHE_MESSAGES_MS if user unsubcribe channel
  private onSubcribeChannelMessages = (params: ISubcribeParams) => {
    const { channelID } = params;
    this.internalStorage.removeTimeoutIfExist(channelID);

    return this.emitter.subscribe({
      ...params,
      onUnsubscribe: () => {
        this.internalStorage.setCurrentSubscribedChannelID((prev) => prev.filter((id) => id !== channelID));
        this.internalStorage.removeMessagesCache(channelID);
      },
    });
  };

  private onGetChannels = async (params: IGetChannelsParams) => {
    await this._subcribeConnectWS();
    await this._subcribeGetGroupPath();

    const group_path = this.internalStorage.data.misc.groupPath;
    const res = await getChannels<IChannels>({
      ...params,
      group_path,
    });

    this.logger.info('Get channels', res.data);
    if (res.error) return showMessage.error(res.error || 'Có lỗi xảy ra, vui lòng thử lại sau');

    return res?.data;
  };

  private onGetChannelInfo = async ({ channel_id }: IGetChannelsInfoParams) => {
    await this._subcribeConnectWS();
    await this._subcribeGetGroupPath();

    const group_path = this.internalStorage.data.misc.groupPath;
    const res = await getChannelsInfo({ group_path, is_favorite: 1, channel_id });
    if (res.error) return showMessage.error(res.error || 'Có lỗi xảy ra, vui lòng thử lại sau');
    return res?.data?.[0];
  };

  private onSubcribeChannel = async ({ channel_id, prev = undefined }) => {
    await this._subcribeConnectWS();
    await this._subcribeGetGroupPath();

    if (!channel_id) return this.logger.error('channel_id is required to subscribe');

    const { token } = this.internalStorage.data.misc;

    if (prev) this.client.wsClient?.send(`${token}|unsub|chats_channel_${prev}`);
    this.client.wsClient?.send(`${token}|sub|chats_channel_${channel_id}`);

    this.internalStorage.setCurrentSubscribedChannelID((prev) => [...prev, channel_id]);

    // Won't get messages if user has subscribed before
    if (!this.internalStorage.data.channelMessages.has(channel_id)) {
      this.internalStorage.setChannelMessageMode(channel_id, [
        IChannelMessageMode.BEFORE,
        IChannelMessageMode.MAX_AFTER,
      ]);
      this.emitter.emitChange(WsListenerType.CHANNEL_MESSAGE_MODE, channel_id);

      await this.onGetMessages({ channel_id, limit: MESSAGES_LIMIT });
    }
  };

  // 4 Mode of getting messages: BEFORE, AFTER, MAX_BEFORE, MAX_AFTER
  private onGetMessages = async (params: IHandleGetListMessageParams, mode?: IChannelMessageMode[]) => {
    await this._subcribeConnectWS();
    await this._subcribeGetGroupPath();

    // Ensure only one getListMessages call at a time for each channel
    if (this.messageStatus.get(params.channel_id) === IStatus.LOADING) {
      await this._subcribeGetMessages(params.channel_id);
      return;
    }

    this.messageStatus.set(params.channel_id, IStatus.LOADING);

    if (mode) {
      this.internalStorage.setChannelMessages(params.channel_id, []);
      this.internalStorage.setChannelMessageMode(params.channel_id, mode);
      this.emitter.emitChange(WsListenerType.CHANNEL_MESSAGE_MODE, {
        channelID: params.channel_id,
      });
    }

    const newParams = {
      ...params,
      before: params?.before
        ? this.internalStorage.data.channelMessages.get(params.channel_id)?.at(-1)?.score
        : undefined,
      after: params?.after
        ? this.internalStorage.data.channelMessages.get(params.channel_id)?.[0]?.score
        : undefined,
    };

    const res = await getListMessages<IChannelMessage[]>(newParams);

    if (res.error) return showMessage.error(res.error || 'Có lỗi xảy ra, vui lòng thử lại sau');

    const messages = convertMessages(res?.data, this.internalStorage.data.misc);

    this.internalStorage.setChannelMessages(params.channel_id, (prev) =>
      params?.after ? [...messages, ...prev] : [...prev, ...messages]
    );

    this.logger.info('Get messages', `- ChannelID: ${params?.channel_id}`, messages);

    if (res.data.length < MESSAGES_LIMIT) {
      if (params?.before) {
        this.internalStorage.setChannelMessageMode(params.channel_id, (prev) => {
          prev[0] = IChannelMessageMode.MAX_BEFORE;
          return prev;
        });
      }
      if (params?.after) {
        this.internalStorage.setChannelMessageMode(params.channel_id, (prev) => {
          prev[1] = IChannelMessageMode.MAX_AFTER;
          return prev;
        });
      }
      this.emitter.emitChange(WsListenerType.CHANNEL_MESSAGE_MODE, { channelID: params.channel_id });
    }

    this.emitter.emitChange(WsListenerType.MESSAGES, { channelID: params.channel_id });
    this.messageStatus.set(params.channel_id, IStatus.IDLE);
  };

  private createFileParams = (files: File[], channelID: IChannelID) => {
    const refID = window.crypto.randomUUID();

    const tempMsg: IChannelMessage = {
      attachments: files.map((file) => ({
        id: uid(6),
        url: URL.createObjectURL(file),
        mime: file.type,
        name: file.name,
        ext: file.name.split('.').pop(),
      })),
      channel_id: channelID,
      text: '',
      created_at: refID,
      msg_type: 'text',
      isCurrentUser: true,
      id: refID,
    };

    this.internalStorage.setChannelMessages(channelID, (prev) => [tempMsg, ...prev]);

    return {
      channel_id: channelID,
      msg_type: IMsgType.TEXT,
      ref_id: refID,
      text: '',
      file_id: null,
    };
  };

  private createTextParams = (message: string, channelID: IChannelID, quoteMessage: IChannelMessage) => {
    const refID = window.crypto.randomUUID();

    const tempMsg: IChannelMessage = {
      channel_id: channelID,
      text: message,
      created_at: refID,
      msg_type: quoteMessage ? 'quote_message' : 'text',
      isCurrentUser: true,
      id: refID,
      quote_message: quoteMessage,
    };

    this.internalStorage.setChannelMessages(channelID, (prev) => [tempMsg, ...prev]);

    return {
      channel_id: channelID,
      msg_type: quoteMessage ? IMsgType.QUOTE_MESSAGE : IMsgType.TEXT,
      quote_message_id: quoteMessage?.id,
      ref_id: refID,
      text: message,
      file_id: null,
    };
  };

  private handleSend: IHandleSend = async (params) => {
    const { channel_id: channelID, ref_id: refID } = params || {};

    this.logger.info('Sending message', params);

    const res = await sendMessage(params);

    if (res?.error) {
      this.internalStorage.setChannelMessages(channelID, (prev) =>
        prev.map((msg) => {
          if (msg.id === refID) {
            msg.error = res.error;
          }

          return msg;
        })
      );
      return this.emitter.emitChange(WsListenerType.MESSAGES, { channelID });
    }

    this.internalStorage.setChannelMessages(channelID, (prev) => prev.filter((msg) => msg.id !== refID));

    return res?.data;
  };

  private handleSendMessage: IHandleSendMessages = async ({
    quoteMessage,
    message,
    files,
    channelID,
    onShowTempMessage,
  }) => {
    if (!channelID) return this.logger.error('ChannelID is required to send message');
    const tempMsgs = [];

    const textParams = message && this.createTextParams(message, channelID, quoteMessage);
    const fileParams = files.length > 0 && this.createFileParams(files, channelID);

    // Show temp message before sending
    this.emitter.emitChange(WsListenerType.MESSAGES, { channelID });

    // If we want to call some functions before sending message (e.g: scroll to bottom)
    if (onShowTempMessage) onShowTempMessage();

    // Message text come first
    if (message) {
      const msg = await this.handleSend(textParams);
      tempMsgs.push(msg);
    }

    if (files.length > 0) {
      const formData = new FormData();
      files.map((attach) => formData.append('attachment', attach));

      const { data, error } = await uploadFile(formData);

      if (error) {
        showMessage.error(error);
        return;
      }

      const msg = await this.handleSend({ ...fileParams, file_id: data?.map((file) => file?.id)?.join(',') });
      tempMsgs.push(msg);
    }
    // If ws is not connected, emit change to update UI
    // Otherwise, ws event will handle this
    if (!this.client.isWsConnected) {
      this.internalStorage.setChannelMessages(channelID, (prev) =>
        convertMessages([...prev, ...tempMsgs], this.internalStorage.data.misc)
      );

      this.emitter.emitChange(WsListenerType.MESSAGES, { channelID });
      this.emitter.emitChange(WsListenerType.CHANNELS, { data: tempMsgs.at(-1) });
    }
  };

  private setGroupPath = (groupPath: IGroupPath) => {
    this.internalStorage.setMisc((prev) => ({ ...prev, groupPath }));
    this.emitter.emitChange(WsListenerType.INTERNAL_GETTING_GROUP_PATH);
  };

  get chatInstance() {
    return {
      wsClient: this.client,

      _wsStorage: this.internalStorage,
      _emitter: this.emitter,
      _onGetChannels: this.onGetChannels,
      _onSubcribeChannel: this.onSubcribeChannel,
      _onGetChannelInfo: this.onGetChannelInfo,
      _onSubcribeChannelMessages: this.onSubcribeChannelMessages,

      handleSetGroupPath: this.setGroupPath,
      handleGetMessages: this.onGetMessages,
      handleSendMessage: this.handleSendMessage,
    };
  }
}
