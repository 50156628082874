import { useContext, useEffect, useState } from 'react';
import { WsListenerType } from '../constants';
import { ChatClientContext } from '../provider';

export const useIsWsConnected = () => {
  const { chatInstance: instance } = useContext(ChatClientContext);
  const [_, reRender] = useState({});

  useEffect(() => {
    const listener = () => reRender({});
    const unSubcribe = instance._emitter.subscribe({
      type: WsListenerType.CONNECT,
      listener,
    });

    return () => {
      unSubcribe();
    };
  }, []);

  return instance.wsClient.isWsConnected;
};
