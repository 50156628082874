import { IChannelData, IChannelID } from '../interfaces';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ROUTES } from '../services/gamp-chat.service';
import { ChatClientContext } from '../provider';
import { useContext } from 'react';

export const useChannel = (channelID: IChannelID) => {
  const queryClient = useQueryClient();
  const { chatInstance: instance } = useContext(ChatClientContext);

  const { data, isFetching } = useQuery<IChannelData>({
    queryKey: [ROUTES.GET_CHANNELS, channelID],
    queryFn: () =>
      instance._onGetChannelInfo({
        channel_id: channelID,
      }),
  });

  const onUpdateChannel = async (newChannel) => {
    queryClient.setQueryData([ROUTES.GET_CHANNELS, channelID], newChannel);
  };

  return {
    detail: data,
    loading: isFetching,
    onUpdateChannel,
  };
};
