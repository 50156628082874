import { useContext, useEffect, useState } from 'react';
import { WsListenerType } from '../constants';
import { IChannelID } from '../interfaces';
import { ChatClientContext } from '../provider';

export const useChannelMsgMode = (channelID: IChannelID) => {
  const { chatInstance: instance } = useContext(ChatClientContext);
  const [_, reRender] = useState({});

  useEffect(() => {
    if (!channelID) return;

    const listener = () => reRender({});
    const unSubcribe = instance._emitter.subscribe({
      type: WsListenerType.CHANNEL_MESSAGE_MODE,
      listener,
      channelID,
    });

    return () => {
      unSubcribe();
    };
  }, [channelID]);

  return instance._wsStorage.data.channelMessageMode?.get(channelID);
};
