import { useContext, useEffect, useMemo } from 'react';
import { CHANNELS_LIMIT, WsListenerType } from '../constants';
import { IGetChannelsParams, ROUTES } from '../services/gamp-chat.service';
import { useLoadMore } from '@/hooks/useLoadMore';
import { useQueryClient } from '@tanstack/react-query';
import { ChatClientContext } from '../provider';
import { IChannelData } from '../interfaces';

interface IUseBaseChannels {
  tag_id: IGetChannelsParams['tag_id'];
  keyword: IGetChannelsParams['keyword'];
}

export const useChannels = (params?: IUseBaseChannels) => {
  const queryClient = useQueryClient();
  const { chatInstance: instance } = useContext(ChatClientContext);

  const queryKey = useMemo(
    () => [ROUTES.GET_CHANNELS, 'list_channels', params?.tag_id, params?.keyword],
    [params?.keyword, params?.tag_id]
  );

  const { keyword, tag_id } = params || {};

  const { data, nodeRef, isFetching } = useLoadMore<IChannelData[]>({
    queryKey,
    fetchFn: (pageParam) =>
      instance._onGetChannels({
        tag_id: tag_id,
        keyword: keyword === '' ? undefined : keyword,
        limit: CHANNELS_LIMIT,
        ...pageParam,
      }),
    initialPageParam: undefined,
    getNextPageParam: (channels) => {
      const lastChannel = channels?.[channels.length - 1] as any;

      return channels?.length < CHANNELS_LIMIT
        ? undefined
        : { after: lastChannel?.score, is_favorite: lastChannel?.is_favorite || 0 };
    },
    staleTime: 0,
    gcTime: 0,
  });

  useEffect(() => {
    const listener = (data: any) => {
      const channelID = data?.channel_id;

      queryClient.setQueryData(queryKey, (prev: any) => {
        const allPages = prev?.pages?.flat();

        const updatedChannelIndex = allPages?.findIndex((item) => item.channel_id === channelID);

        if (updatedChannelIndex === -1) {
          return prev;
        }

        // Update last message
        const updatedChannel = {
          ...allPages[updatedChannelIndex],
          last_message: data,
        };

        const newAllPages = [updatedChannel, ...allPages.filter((item) => item.channel_id !== channelID)];

        const newPages = newAllPages.reduce((acc, item, index) => {
          const chunkIndex = Math.floor(index / CHANNELS_LIMIT);
          acc[chunkIndex] = [...(acc[chunkIndex] || []), item];
          return acc;
        }, []);

        return {
          ...prev,
          pages: newPages,
        };
      });
    };
    const unSubcribe = instance._emitter.subscribe({ type: WsListenerType.CHANNELS, listener });

    return () => {
      unSubcribe();
    };
  }, [queryKey]);

  const getChannels = (params: IGetChannelsParams) => instance._onGetChannels({ is_favorite: 1, ...params });

  return { channels: data, getChannels, loading: isFetching, nodeRef };
};
