import { Json } from '@/utils/Json';
import {
  INTERVAL_CHECK_PING,
  KEEP_CONNECTION_MESSAGE,
  RECONNECT_LIMIT,
  RECONNECT_TIMEOUT,
  WsListenerType,
} from '../constants';
import { Logger } from './Logger';
import { Emitter } from './Emitter';
import { eventReducer } from '../events';
import { InternalStorage } from './InternalStorage';
import { IUser } from '../interfaces';

// Ws State
enum WSState {
  CONNECTING = 0,
  OPEN = 1,
  CLOSED = 3,
}

export class WSClient {
  private client: WebSocket;
  private wsState: WSState;

  private logger: Logger;
  private emitter: Emitter;
  private internalStorage: InternalStorage;

  private reconnectCount: number;
  private intervalPingId: NodeJS.Timeout;
  private timeoutPingId: NodeJS.Timeout;
  private onReconnect?: () => void;

  constructor(
    _logger: Logger,
    _emitter: Emitter,
    _internalStorage: InternalStorage,
    onReconnect?: () => void
  ) {
    this.wsState = WSState.CLOSED;

    this.reconnectCount = 0;

    this.logger = _logger;
    this.emitter = _emitter;
    this.internalStorage = _internalStorage;
    this.onReconnect = onReconnect;
  }

  private onCloseWs = (userInfo: IUser) => {
    if (this.wsState === WSState.CONNECTING) return;

    this.wsState = WSState.CONNECTING;
    this.emitter.emitChange(WsListenerType.CONNECT);

    this.intervalPingId && clearInterval(this.intervalPingId);
    this.timeoutPingId && clearTimeout(this.timeoutPingId);

    if (this.reconnectCount >= RECONNECT_LIMIT) {
      return this.logger.error(`WS reconnect limit reached (${RECONNECT_LIMIT})`);
    }

    this.reconnectCount++;
    this.logger.info(
      `WS reconnecting... Attempt ${this.reconnectCount} - ${
        RECONNECT_TIMEOUT[this.reconnectCount - 1] / 1000
      }s`
    );
    setTimeout(() => this.initializeWs(userInfo), RECONNECT_TIMEOUT[this.reconnectCount - 1]);
  };

  private onPing = (userInfo: IUser) => {
    this.client.send(KEEP_CONNECTION_MESSAGE.PING);

    this.timeoutPingId = setTimeout(() => {
      this.logger.info('WS closing...');
      this.wsState = WSState.CLOSED;
      this.emitter.emitChange(WsListenerType.CONNECT);

      this.client.close();
      this.onCloseWs(userInfo);
    }, RECONNECT_TIMEOUT[0]);
  };

  public initializeWs = (userInfo: IUser) => {
    if (this.wsState === WSState.OPEN) return;

    const token = localStorage.getItem('token');
    const deviceId = localStorage.getItem('device_id');
    const appVersion = process.env.buildId;

    if (!token) return this.logger.error('Token is required to initialize WS');

    const params = {
      Authorization: token,
      appVersion,
      deviceId,
      appType: 'gChat',
      device: 'web',
      source: 'gamp-chats',
    };

    const WS_URL = `${process.env.NEXT_PUBLIC_GAM_CHAT_WS}/ws/chat?${new URLSearchParams(params)}`;
    this.client = new WebSocket(WS_URL);
    this.internalStorage.setMisc((prev) => ({ ...prev, token, user: userInfo }));

    this.logger.info('WS initializing...', WS_URL);

    this.client.onopen = () => {
      this.onPing(userInfo);

      // Send ping to server every INTERVAL_CHECK_PING to check if connection is still alive by receiving 55 from server,
      // if not setTimeout to auto close ws after RECONNECT_TIMEOUT and reconnect
      this.intervalPingId = setInterval(() => {
        this.onPing(userInfo);
      }, INTERVAL_CHECK_PING);
    };

    this.client.onmessage = (event: MessageEvent) => {
      const stringData = event.data;

      if (stringData === KEEP_CONNECTION_MESSAGE.PONG) {
        if (this.wsState !== WSState.OPEN) {
          if (this.reconnectCount > 1) {
            // Resubscribe to channels when reconnect
            this.onReconnect?.();
          }
          // Only subscribe to user 1 time
          this.client.send(`${token}|sub|chats_user_${userInfo?.user_id}`);

          this.logger.info('WS connected');
          this.reconnectCount = 1;
          this.wsState = WSState.OPEN;

          this.emitter.emitChange(WsListenerType.INTERNAL_CONNECT_WS);
          this.emitter.emitChange(WsListenerType.CONNECT);
        }
        clearTimeout(this.timeoutPingId);

        return;
      }

      const data = Json.parse(stringData);

      if (!data) return;

      eventReducer(data, this.internalStorage, this.emitter.emitChange, this.logger);
    };

    this.client.onerror = () => {
      this.intervalPingId && clearInterval(this.intervalPingId);
      this.timeoutPingId && clearTimeout(this.timeoutPingId);
      this.wsState = WSState.CLOSED;
      this.emitter.emitChange(WsListenerType.CONNECT);
    };

    this.client.onclose = () => {
      this.onCloseWs(userInfo);
    };
  };

  get isWsConnected() {
    return this.wsState === WSState.OPEN;
  }

  get wsClient() {
    return this.client;
  }
}
