import { useContext, useEffect, useState } from 'react';
import { WsListenerType } from '../constants';
import { IChannelID } from '../interfaces';
import { flushSync } from 'react-dom';
import { ChatClientContext } from '../provider';
import { useQuery } from '@tanstack/react-query';
import { ROUTES } from '../services/gamp-chat.service';

export const useChannelMessage = (channelID: IChannelID) => {
  const { chatInstance: instance } = useContext(ChatClientContext);
  const [_, reRender] = useState({});

  useQuery({
    queryKey: [ROUTES.MESSAGE, channelID],
    queryFn: () => {
      instance._onSubcribeChannel({ channel_id: channelID });
      return {};
    },
    enabled: !!channelID,
    staleTime: 0,
    gcTime: 0,
  });

  useEffect(() => {
    if (!channelID) return;

    // Update Dom synchronously in order to avoid flickering when scroll to bottom
    const listener = () => flushSync(() => reRender({}));

    const unSubcribe = instance._onSubcribeChannelMessages({
      type: WsListenerType.MESSAGES,
      listener,
      channelID,
    });

    return () => {
      unSubcribe();
    };
  }, [channelID]);

  const currentChannelMessages = instance._wsStorage.data.channelMessages?.get(channelID) || [];

  return {
    channelMessages: [...currentChannelMessages].reverse(),
    loading: currentChannelMessages?.length === 0,
  };
};
