import React, { ReactNode, useEffect } from 'react';
import { ChatClient } from '../';
import { useAuth } from '@/configs/Auth/client';

interface ChatProviderProps {
  client: ChatClient;
  initializeWsOnMount?: boolean;
  children: ReactNode;
}

type UseChatClientValue = Omit<
  ChatClient['chatInstance'],
  | '_wsStorage'
  | '_emitter'
  | '_onGetChannels'
  | '_onSubcribeChannel'
  | '_onGetChannelInfo'
  | '_onSubcribeChannelMessages'
>;

export const ChatClientContext = React.createContext<ChatClient>(null);

export const ChatClientProvider: React.FC<ChatProviderProps> = ({
  children,
  client,
  initializeWsOnMount = false,
}) => {
  const { userInfo } = useAuth();

  useEffect(() => {
    if (!initializeWsOnMount || !userInfo) return;

    client.chatInstance.wsClient.initializeWs(userInfo);
  }, [initializeWsOnMount, userInfo]);

  return <ChatClientContext.Provider value={client}>{children}</ChatClientContext.Provider>;
};

export const useChatClient = (): UseChatClientValue => {
  const context = React.useContext(ChatClientContext);

  if (!context) {
    throw new Error('useChatProvider must be used within a ChatProvider');
  }

  return {
    wsClient: context.chatInstance.wsClient,
    handleSetGroupPath: context.chatInstance.handleSetGroupPath,
    handleGetMessages: context.chatInstance.handleGetMessages,
    handleSendMessage: context.chatInstance.handleSendMessage,
  };
};
